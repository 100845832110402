import _ from "lodash";
import React, { createContext, FC, useEffect, useMemo, useState } from "react";

import TenantBusiness from '../interfaces/TenantBusiness';
import UserType from '../interfaces/User';
import formatNumber from '../utils/formatNumber';
import { TenantConfigTypes } from '../shared/enums/TenantConfigs';

const SESSION_BUSINESS_KEY = "selectedBusinessId";
const SEARCH_PARAM_BUSINESS_KEY = "bu";

interface UserContextType {
  currentUser: UserType;
  businesses: TenantBusiness[];
  selectedBusiness: TenantBusiness;
  userPermissions: string[];
  businessUnitNameMap: { [key: string]: TenantBusiness };
  setBusiness: (businessId: number) => void;
  getTenantLevelConfig: <T = Record<string, any>>(
    configType: TenantConfigTypes
  ) => T | null;
  formatCurrency: (currency: number) => string;
  disableBusinessSwitch: boolean;
  setDisableBusinessSwitch: (value: boolean) => void;
  setSelectedBusiness: React.Dispatch<React.SetStateAction<TenantBusiness>>;
}

const UserContext = createContext<UserContextType>({
  currentUser: {} as any,
  businesses: [],
  selectedBusiness: {} as any,
  userPermissions: [],
  businessUnitNameMap: {},
  setBusiness(businessId: number) {},
  formatCurrency(currency: number) {
    return '';
  },
  getTenantLevelConfig(_configType: TenantConfigTypes) {
    return null;
  },
  disableBusinessSwitch: false,
  setDisableBusinessSwitch(value: boolean) {},
  setSelectedBusiness() {},
});

export const UserProvider: FC<
  React.PropsWithChildren & { currentUser: UserType }
> = ({ currentUser, children }) => {
  const businesses = useMemo(
    () =>
      currentUser.tenant.brands.reduce((acc, val) => {
        acc.push(...val.businesses);
        return acc;
      }, [] as TenantBusiness[]),
    [currentUser]
  );

  const [selectedBusiness, setSelectedBusiness] = useState(() => {
    const sessionBusinessId =
      getBusinessIDFromSearchParams() || getBusinessIDFromSession();

    if (sessionBusinessId) {
      const business = getBusinessById(sessionBusinessId);
      if (business) return business;
    }
    return businesses[0];
  });

  const [disableBusinessSwitch, setDisableBusinessSwitch] =
    useState<boolean>(false);

  useEffect(() => {
    selectedBusiness.id !== getBusinessIDFromSession() &&
      saveBusinessIDInSession(selectedBusiness.id);
  }, [selectedBusiness]);

  const userPermissions = useMemo(() => {
    return (
      currentUser?.tenant_role_groups.reduce((acc, val) => {
        if (_.isArray(val.permissions)) {
          acc.push(...val.permissions);
          return acc;
        }

        const permissions = Object.values(val.permissions).reduce(
          (acc, { enabled }) => acc.concat(enabled),
          [] as string[]
        );

        acc.push(...permissions);

        return acc;
      }, [] as string[]) || []
    );
  }, [currentUser]);

  const businessUnitNameMap = useMemo(() => {
    return (
      currentUser?.tenant.brands.reduce((acc, val) => {
        val.businesses.forEach((business) => {
          acc[business.name] = business;
        });

        return acc;
      }, {} as { [key: string]: TenantBusiness }) || {}
    );
  }, [currentUser]);

  const handleBusinessChange = (businessId: number) => {
    const business = getBusinessById(businessId);

    if (!business) {
      throw new Error(`No Business found for id ${businessId}`);
    } else setSelectedBusiness(business);
  };

  function getBusinessById(businessId: number): TenantBusiness | undefined {
    return businesses.find(({ id }) => id === businessId);
  }

  function getTenantLevelConfig<T = Record<string, any>>(
    _configType: TenantConfigTypes
  ) {
    const tenantConfigDetails = currentUser.tenant.tenantLevelConfigs.find(
      ({ configType }) => configType === _configType
    );
    if (!tenantConfigDetails) {
      return null;
    }
    return tenantConfigDetails.configDetails as T;
  }

  const formatCurrency = (currency: number) => {
    return formatNumber(currency, {
      currency: selectedBusiness.country,
      style: 'currency',
      notation: 'compact',
    });
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        businesses,
        selectedBusiness,
        userPermissions,
        businessUnitNameMap,
        setBusiness: handleBusinessChange,
        getTenantLevelConfig,
        formatCurrency,
        disableBusinessSwitch,
        setDisableBusinessSwitch,
        setSelectedBusiness
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

const getBusinessIDFromSession = () => {
  const selectedBusiness = sessionStorage.getItem(SESSION_BUSINESS_KEY);
  return selectedBusiness ? +selectedBusiness : null;
};

const getBusinessIDFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.has(SEARCH_PARAM_BUSINESS_KEY)
    ? +searchParams.get(SEARCH_PARAM_BUSINESS_KEY)!
    : null;
};

const saveBusinessIDInSession = (businessId: number) => {
  return sessionStorage.setItem(SESSION_BUSINESS_KEY, businessId.toString());
};
