import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ManageJourney = lazy(() => import('../components/ManageJourney'));
const JourneyListContainer = lazy(
  () => import('../components/JourneyListContainer/JourneyListContainer')
);
const JourneyDrafts = lazy(
  () => import('../components/JourneyDrafts/JourneyDrafts')
);

const journeyListRoutes: RouteObject = {
  path: 'journeys',
  element: <ManageJourney />,
  children: [
    {
      index: true,
      element: <JourneyListContainer />,
    },
    {
      path: 'drafts',
      element: <JourneyDrafts />,
    },
  ],
};

export default journeyListRoutes;
