import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const StorePrediction = lazy(() => import('../components/StorePrediction'));

const storePrediction: RouteObject = {
  path: '/customer-cube/store-sales-forecast',
  element: <StorePrediction />,
};

export default storePrediction;
