import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const ProductAttributes = lazy(() => import("../components/ProductAttribute"));

const productAttributes: RouteObject = {
  path: "/commerce-connect/product-attributes",
  element: <ProductAttributes />,
};

export default productAttributes;
