import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const qaConfigs: EnvironmentConfig = {
  surveyJsKey: 'NzQ2YTcwNDMtMDcxMS00NjhkLWIyZjYtNDBmOTJjOTQwMzU3JmRvbWFpbnM6ZGFzaGJvYXJkLmNhc2FxYS5hamlyYS50ZWNoOzE9MjAyNS0wMy0wNywyPTIwMjUtMDMtMDcsND0yMDI1LTAzLTA3',

  dashboardClassic: 'https://dashboard-classic.casaqa.ajira.tech',
  ruleServerUrl: 'https://api.casaqa.ajira.tech',
  apiServerUrl: 'https://api.customerstudioqa.ajira.tech',  
  casaWebAppUrl: 'https://web.casaqa.ajira.tech',
  commerceConnect: 'https://commerce-connect.casaqa.ajira.tech',
  pwaUrl: 'qa.casarewards.com',
  dashboard: 'https://dashboard.casaqa.ajira.tech',
  ruleUIUrl: 'https://rules.casaqa.ajira.tech',
  keycloakConfig: {
    url: `https://auth.casaqa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
  },
  socialMedia: {
    facebook: {
      appId: '787341686188506',
    },
  },
};



export default qaConfigs;
